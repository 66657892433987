div.voucherifyContainer {
    position: relative;
    display: block;

    width: 12em;

    margin: 1.75em auto 0 auto;
    border: 1px solid #E5E5E5;
    border-radius: 1em;
    padding: 2.5em 1em 0 1em;

    background-color: #F1F1F1;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(229,229,229,0.2);
	-moz-box-shadow: 0px 0px 2px 2px rgba(229,229,229,0.2);
	box-shadow: 0px 0px 2px 2px rgba(229,229,229,0.2);

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

div.voucherifyContainer *,
div.voucherifyContainer *::before,
div.voucherifyContainer *::after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

div.voucherifyContainer.wide {
    width: 22em;
}

div.voucherifyContainer figure.voucherifyLogo {
	position: absolute;
	top: -1.5em;
	left: 50%;

	width: 2em;
	height: 2em;

	margin-top: 0;
	margin-bottom: 0;
    margin-left: -1.5em;
	border: 1px solid #E5E5E5;
	border-radius: 2em;
	padding: 0.5em;

	text-align: center;
	background-color: white;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(229,229,229,0.2);
	-moz-box-shadow: 0px 0px 2px 2px rgba(229,229,229,0.2);
	box-shadow: 0px 0px 2px 2px rgba(229,229,229,0.2);
}

div.voucherifyContainer img.voucherifyLogo {
    position: relative;
    width: 2em;
    height: 2em;
}

div.voucherifyContainer span.voucherifyMessage {
    display: block;

    margin: 0.4em 0;
    padding: 0;

    font-size: 0.85em;

    text-align: center;
}

div.voucherifyContainer span.voucherifyMessageInvalid {
    color: red;
}

div.voucherifyContainer span.voucherifyMessageValid {
    color: green;
}

div.voucherifyContainer input.voucherifyDiscountType,
div.voucherifyContainer input.voucherifyPercentOff,
div.voucherifyContainer input.voucherifyAmountOff,
div.voucherifyContainer input.voucherifyUnitOff,
div.voucherifyContainer input.voucherifyTracking {
    display: none;
}

div.voucherifyContainer input.voucherifyCode,
div.voucherifyContainer input.voucherifyAmount,
div.voucherifyContainer input.voucherifyCustomerName,
div.voucherifyContainer input.voucherifyCustomerEmail,
div.voucherifyContainer input.voucherifyCustomerPhone,
div.voucherifyContainer input.voucherifyCustomerLine1,
div.voucherifyContainer input.voucherifyCustomerLine2,
div.voucherifyContainer input.voucherifyCustomerPostalCode,
div.voucherifyContainer input.voucherifyCustomerCity,
div.voucherifyContainer input.voucherifyCustomerState,
div.voucherifyContainer input.voucherifyCustomerCountry,
div.voucherifyContainer input.voucherifyPublishStatus  {
    display: block;
    box-sizing: border-box;
    color: #444444;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #FFFFFF;

    height: 2.5em;
    width: 100%;
	margin: 0.5em 0;

    border: 1px solid #D1D1D1;
    padding: 0 1em;

    font-size: 0.95em;
    text-align: center;
    outline: none;
}
div.voucherifyContainer input.voucherifyCode.voucherifyInvalid,
div.voucherifyContainer input.voucherifyAmount.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerName.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerEmail.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerPhone.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerLine1.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerLine2.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerPostalCode.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerCity.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerState.voucherifyInvalid,
div.voucherifyContainer input.voucherifyCustomerCountry.voucherifyInvalid {
	background-color: #f8dffe;
    border: 1px solid #EBD7F4;
}

div.voucherifyContainer input.voucherifyCode.voucherifyValid,
div.voucherifyContainer input.voucherifyAmount.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerName.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerEmail.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerPhone.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerLine1.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerLine2.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerPostalCode.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerCity.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerState.voucherifyValid,
div.voucherifyContainer input.voucherifyCustomerCountry.voucherifyValid,
div.voucherifyContainer button.voucherifyPublish.voucherifyValid {
    border: 1px solid #08ffb9;
    background-color: #08ffb9;
    color: rgba(63,49,49,.8);
    font-weight: bold;
}

div.voucherifyContainer .voucherifyRow {
    display: flex;
}

div.voucherifyContainer .voucherifyRow > input {
    flex: 1;
}

div.voucherifyContainer .voucherifyRow > input:not(:last-of-type) {
    margin-right: 0.8em;
}

div.voucherifyContainer button.voucherifyRedeem,
div.voucherifyContainer button.voucherifyValidate,
div.voucherifyContainer button.voucherifyPublish {
	display: block;

    margin: 1.5em auto 1.5em auto;
    border: none;
    border-radius: 2em;
    padding: 0 1.75em;

    cursor: pointer;
    outline: none;

    line-height: 3em;
    height: 3em;

    font-size: 0.8em;
    font-weight: bold;

    text-transform: uppercase;
    background-color: #08ffb9;
    color: rgba(63,49,49,.8);
}

div.voucherifyContainer button.voucherifyValidate:hover,
div.voucherifyContainer button.voucherifyPublish:hover {
	background-color: rgba(8,255,185,.8);
}

div.voucherifyContainer button.voucherifyValidate:disabled,
div.voucherifyContainer button.voucherifyPublish:disabled {
    background-color: #E5E5E5;
    color: #959595;
    cursor: default;
}

div.voucherifyContainer button.voucherifyPublish.voucherifyValid {
    background-color: #08ffb9;
    color: rgba(63,49,49,.8);
    font-weight: bold;
}

div.voucherifyContainer button.voucherifyPublish.voucherifyInvalid {
    background-color: #f8dffe;
}

/****
 * From Animate.css: https://daneden.github.io/animate.css/ 
 ****/
@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
}

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.voucherifyAnimationShake {
  -webkit-animation-name: shake;
  animation-name: shake;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.voucherifyAnimationTada {
  -webkit-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
